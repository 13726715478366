import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import Alert from '../../components/Alert/Alert';
import IFrameCont from '../../components/iFrame/iFrame';

const FileIllegalComplaintPage = () => {
	const intl = useIntl();

	return (
		<Layout
			subheader={<FormattedMessage id='complaintForms.subHdr' />}
			header={<FormattedMessage id='complaintForms.illegalHdr' />}
		>
			<SEO tlang={intl.locale} title={intl.formatMessage({ id: 'nav.fileComplaint' })} />
			<Container>
				<Row>
					<Col>
						<IFrameCont
							title='File An Illegal Building Complaint Form'
							src={
								intl.locale === 'en'
									? 'https://builderportal.hcraontario.ca/en-US/illegal-building-complaint-form/'
									: 'https://builderportal.hcraontario.ca/fr-FR/illegal-building-complaint-form/'
							}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default FileIllegalComplaintPage;
