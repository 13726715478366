import React, { useState, useEffect } from 'react';

const IFrameCont = ({ src, title }) => {
	const [height, setHeight] = useState(0);

	useEffect(() => {
		window.addEventListener(
			'message',
			function (e) {
				let message = e.data;
				// console.log('message received: ', message)
				setHeight(message.height);
			},
			false
		);
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}, []);

	return <iframe id='iFrame' title={title} style={{ height: `${height}px` }} src={src}></iframe>;
};

export default IFrameCont;
